.myheader {
    background-image: linear-gradient(170deg, lightblue 0%, #0a192f 55%);
    height: 500px;
    font-size: 28px;
    text-align: center;
  }
.name{
    color: #fdfdfd;
    font-family: Verdana;
    font-size: 60px;
    margin: 0px;
}